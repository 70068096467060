import { View } from 'components/01_tw/View';
import { ButtonHTMLAttributes, Ref, forwardRef } from 'react';
import { tw } from 'twind';

/**
 * WORK IN PROGRESS
 *
 */
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    colorScheme: 'blue' | 'gray-light' | 'gray-dark';
    innerRef?: Ref<HTMLButtonElement>;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    variant: 'solid' | 'outline' | 'ghost' | 'inverted' | 'contrast' | 'white';
    weight?: 'medium' | 'semibold';
}

const Button = ({
    colorScheme = 'blue',
    children,
    className,
    disabled,
    innerRef,
    size = 'md',
    variant = 'solid',
    weight = 'medium',
    ...buttonProps
}: ButtonProps) => {
    const colorSchemeGray = colorScheme === 'gray-light' || colorScheme === 'gray-dark';
    const colorGhostHover = `${
        !disabled && `${colorScheme === 'gray-dark' ? 'bg-gray-200' : 'bg-gray-100'}(hover:& active:& focus-visible:&)`
    }`;

    const variantStyles = {
        solid: `items-center ${
            !disabled &&
            `hover:bg-${
                colorSchemeGray ? (colorScheme === 'gray-dark' ? 'gray-300' : 'gray-200') : `${colorScheme}-600`
            } bg-${colorSchemeGray ? (colorScheme === 'gray-dark' ? 'gray-200' : 'gray-100') : `${colorScheme}-500`}`
        } focus:shadow-inner-2`,
        ghost: `shadow-inner-2(active:& focus-visible:&) ${colorGhostHover}`,
        outline: `ring-${colorSchemeGray ? 'gray-300' : `${colorScheme}-500`} bg-${
            colorSchemeGray ? (colorScheme === 'gray-dark' ? 'gray-200' : 'gray-100') : `${colorScheme}-600`
        }(hover:& active:& focus-visible:&)`,
        inverted: `bg-transparent text-blue-500 border border-${colorScheme}-500 hover:bg-${colorScheme}-500 hover:text-white`,
        contrast: `bg-emerald-500 !text-white`,
        white: `bg-white text-gray-600 hover:bg-gray-100 hover:text-gray-700 border border-[#ced4da] focus:bg-gray-100active:text-gray-700 focus:text-gray-700 !h-[40px] !w-[40px] p-2`
    };

    return (
        <View
            as="button"
            className={tw(
                'rounded font-sans transition-colors ease-in-out duration-150 focus-visible:(ring(2 offset-2 blue-700))',
                { 'ring(1 inset)': variant === 'outline' },
                {
                    'px-8 py-4 text-lg': size === 'lg',
                    'px-6 py-3 text-base': size === 'md',
                    'px-4 py-2.5 text-sm': size === 'sm',
                    'px-2.5 py-1.5 text-xs': size === 'xs'
                },
                {
                    'text-white': !colorSchemeGray && variant === 'solid',
                    'text-blue-500 text-blue-600(hover:& focus:& active:&)':
                        colorScheme === 'blue' && variant !== 'solid',
                    'text-gray-700 text-gray-800(hover:& focus:& active:&)':
                        colorSchemeGray && (variant === 'ghost' || variant === 'outline'),
                    'text-white(hover:& focus:& active:&)': !colorSchemeGray && variant === 'outline'
                },
                {
                    'font-semibold': weight === 'semibold',
                    'font-medium': weight === 'medium'
                },
                { [`hover:cursor-not-allowed bg-${colorScheme}-400`]: disabled },
                variantStyles[variant],
                className
            )}
            ref={innerRef}
            disabled={disabled}
            {...buttonProps}
        >
            {children}
        </View>
    );
};

export default forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => <Button {...props} innerRef={ref} />);
